import { useState, useEffect } from "react";

import '../App.css';
import i18next from '../i18n'

import axios from 'axios';
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';


function Blog() {

  const [data, setData] = useState(null);
  const [response, setResponse] = useState('');

  useEffect(() => {
    getData()
  }, []);

  async function getData() {
    // axios.get('/api/blog')
    //     .then(res => {
    //       console.log("data recieved: ", res.data);
    //       setData(res.data);
    //     });


    const query = `
    {
      blogPosts {
          items {
              id
              title
              content
              image
          }
      }
  }`;

    const endpoint = "/data-api/graphql";
    const response = await fetch(endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ query: query })
    });
    const result = await response.json();
    console.table(result.data.blogPosts.items);
    setData(result.data.blogPosts.items);
  }

  async function deletePost(id) {
    // axios.delete(`/api/blog/:${id}`)
    //   .then(response => {
    //     console.log(response)
    //     toast('Post deleted.')
    //     getData();
    //   });



    const gql = `
    mutation del($id: ID!, $_partitionKeyValue: String!) {
      deleteBlogPost(id: $id, _partitionKeyValue: $_partitionKeyValue) {
        id
      }
    }`;

    const query = {
      query: gql,
      variables: {
        id: id,
        _partitionKeyValue: id
      }
    };

    const endpoint = "/data-api/graphql";
    const response = await fetch(endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(query)
    });

    const result = await response.json();
    console.log(`Record deleted: ${JSON.stringify(result.data)}`);

    toast('Post deleted.')
    getData();
  }

  return (

    <div className='blog'>
      <header className="header">
        <h1>{i18next.t('pages.blog.title')}</h1>
      </header>

      <div className='content'>



        {data ? data.map(post =>

          <div className='card blogpost' key={post.id}>
            <div className="blog-image"><img src={post.image}></img></div>
            <div className="blog-content">
              <Link to={`${post.id}`}>
                <h2>{post.title}</h2>
              </Link>
              <div>
                {post.summary}

              </div>
              {/* <div className="card-admin">
                <Link to={`../admin/edit/${post.id}`}>Edit</Link>
                <a onClick={() => deletePost(post.id)}>Delete</a>
              </div> */}
            </div>

          </div>

        ) : <>
          <div className='card blogpost' >
            <div className="blog-image"></div>
            <div className="blog-content"></div>
          </div>
          <div className='card blogpost' >
            <div className="blog-image"></div>
            <div className="blog-content"></div>
          </div>
        </>
        }

        {/* 
        <Link to={`../admin/create`} className="round-button">Create blog post</Link>
 */}

      </div>
    </div >
  );
}

export default Blog;



