import '../App.css';

import {teamMemberData} from './components/teamMemberData';
import React from 'react';
import TeamMember from './components/TeamMember';
import i18next from '../i18n'

const handshake_image = require("../images/handshake.png")


const bg_image = require("../images/groves.png")

function About() {
  


  return (
    
      <div className='about'>
        <div className='header'>

         <h1>{i18next.t('pages.about.title')}</h1>
    
        </div>

        <div className='content'>
          
        <p className='undertitle'>{i18next.t('pages.about.p-1')}</p>

        <h2>{i18next.t('pages.about.h2')}</h2>
        <div className='devider'> 
          <div > 
              <p>
                {i18next.t('pages.about.p-2')}
                <br /> <br />
                {i18next.t('pages.about.p-3')}
                
              </p>
            </div>
            <div className='image-container'></div>
        </div>
        <div className='devider'> 
        
        <div className='image-container'></div>
          <div > 
              <p>
                {i18next.t('pages.about.p-4')}
                <br /> <br />
                {i18next.t('pages.about.p-5')}
              </p>
            </div>
        </div>
          <div  className='devider devider-2'>
            <div>{i18next.t('pages.about.p-6')}</div> 
            <img alt="Håndhilse" src={handshake_image} className="card-icon" />
           
            <div>
              {i18next.t('pages.about.p-7')}
              <br /><br />
              {i18next.t('pages.about.p-8')}
              <br /><br />
              {i18next.t('pages.about.p-9')}
            </div>
            <div>
              <button className='button'>{i18next.t('common-words.click-button')}</button>   
            </div>  </div>
        </div>
        <div className="team"> 
          
          <h2>{i18next.t('pages.about.team-title')}</h2>
          <div className="teamMembers">
            
            {
              teamMemberData.map((e, i) => <TeamMember data={e} /> )
            
            }
          </div>
        
        </div >
      </div>
   );
}

export default About;
