import React, { Suspense } from 'react';
import './App.css';
import Nav from './Nav';
import Footer from './Footer';
import About from './pages/About';
import Automation from './pages/Automation';
import Blog from './pages/Blog';
import CCM from './pages/CCM';
import Communication from './pages/Communication';
import Contact from './pages/forms/Contact';
import Integrations from './pages/Integrations';
import Customers from './pages/Customers';
import Partners from './pages/Partners';
import UniversalDesign from './pages/UniversalDesign';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import CreateBlogPost from './admin/CreateBlogPost';
import BlogPost from './pages/components/BlogPost';
import EditBlogPost from './admin/EditBlogPost';
import toast, { Toaster } from 'react-hot-toast';
import Login from './admin/Login';
import SeminarForm from './pages/forms/SeminarForm';


function App() {

  useTranslation();

  return (
    <Suspense fallback="loading">


      <HelmetProvider>
        <div className="App">
          <Toaster />
          <Helmet>
            <title>XPER Consulting</title>
          </Helmet>
          <Nav />

          <main>
            <Routes>
              <Route path="/" element={<Main />} />
              {/* <Route path="/om-xper" element={<About />} /> */}
              <Route path="/blogg" element={<Blog />} />

             {/*  <Route path="/blogg/:id" element={<BlogPost />} />*/}

              <Route path="/ccm" element={<CCM />} />
              <Route path="/universal-design" element={<UniversalDesign />} />
              <Route path="/skjema/kontakt-oss" element={<Contact />} />
              <Route path="/skjema/seminar" element={<SeminarForm />} />
              <Route path="/kunder" element={<Customers />} />
              {/*
              <Route path="/integrasjoner" element={<Integrations />} />
              <Route path="/automatisering" element={<Automation />} /> 
              <Route path="/kommunikasjon" element={<Communication />} />
              */}
              <Route path="/partnere" element={<Partners />} />

              {/*
              <Route path="/admin/create" element={<CreateBlogPost />} />
              <Route path="/admin/login" element={<Login />} />
             
              <Route path="/admin/edit/:id" element={<EditBlogPost />} /> */}
            </Routes>
          </main>

          <Footer />
        </div>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
