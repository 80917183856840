import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../App.css';
import { useTranslation } from 'react-i18next';

import Slider from "react-slick";
import i18next from '../i18n'


const companiy_logos = [
  {
    name: "KLP",
    img: require("../images/partners/klp-logo-rgb.jpeg"),
  },
  {
    name: "Equinor",
    img: require("../images/partners/equinor_horiz_logo_rgb_red.png"),
  },
  {
    name: "DNB",
    img: require("../images/partners/DNB_ASA_logo.png"),
  },
  {
    name: "Gjensidige",
    img: require("../images/partners/gjensidige-logo.png"),
  },
  {
    name: "Circle K",
    img: require("../images/partners/cicle-k-logo.png"),
  },
  {
    name: "Nav",
    img: require("../images/partners/nav-logo-1200x.jpg")
  }
]

const integration_img = require("../images/image 6.png")
const auomation_img = require("../images/image 7.png")
const communication_img = require("../images/image 8.png")
const ccm_img = require("../images/image 11.png")

const workflow_img = require("../images/workflow.png")


function Main() {


  useTranslation();

  var settings = {
    dots: true,
    arrows: false,
    autoplay: false,
    infinite: true,
    dotsClass: 'slick-dots-fw slick-dots',
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };



  return (



    <div className='main'>
      <Slider {...settings} className='slider' aria-roledescription="carousel" aria-label="Kompetansefelt slideshow">
        <div className='slide processautomation'>
          <h1 className="slide-header">{i18next.t('pages.main.carousel-1.title')}</h1>
          <div className='slide-images' >
            <div className='slide-image bottom' role="dialog" aria-modal="true" aria-labelledby="integrasjon-h" >
              <img alt={"Integrasjon " + i18next.t('common-words.illustration')} src={integration_img} />
              <h2 id="integrasjon-h" className="slide-title">{i18next.t('pages.main.carousel-1.integrations')}</h2>
            </div>
            <div className='slide-image' role="dialog" aria-modal="true" aria-labelledby="automatisering-h" >
              <img alt={"Automatisering " + i18next.t('common-words.illustration')} src={auomation_img} />
              <h2 id="automatisering-h" className="slide-title">{i18next.t('pages.main.carousel-1.automation')}</h2>
            </div>
            <div className='slide-image bottom' role="dialog" aria-modal="true" aria-labelledby="kommunikasjon-h" >
              <img alt={"Kommunkasjon " + i18next.t('common-words.illustration')} src={communication_img} />
              <h2 id="kommunikasjon-h" className="slide-title">{i18next.t('pages.main.carousel-1.communication')}</h2>
            </div>
          </div>
        </div>
        <div className='slide'>
          <div className="slide-header">{i18next.t('pages.main.carousel-2.title')}</div>
          <div className='slide-images'>
            <div className='slide-image overflow'>
              <img alt={"CCM " + i18next.t('common-words.illustration')} src={ccm_img} />
            </div>
          </div>

        </div>

      </Slider>

      <div className='partners-wrapper'>

        <div className='partners-scroll'>

          <div className='partners'>
            {
              companiy_logos.map(image => (
                <img alt={image.name + " " + i18next.t('common-words.logo')} key={image.name + "_" + i18next.t('common-words.logo')} src={image.img} />
              ))
            }
          </div>
        </div>
      </div>

      {/* 
          <p className="quote">“ {i18next.t('pages.main.quote')} “</p>
          
          <img alt="Workflow illustrasjon" className='workflow' src={workflow_img} /> */}
    </div>
  );
}

export default Main;
