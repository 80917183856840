import React from 'react';
import './App.css';
import { NavLink, Link } from 'react-router-dom';
import logo from './images/Flowize_logo.svg';
import logo2 from './images/1_1_logo_XPER_blue_woBg.png';
import logo3 from './images/1_2_logo_XPER_white_woBg.png'; 

import { Cross as Hamburger } from 'hamburger-react'

//import ReactFlagsSelect from "react-flags-select";

import i18next from './i18n'
import i18n from 'i18next';
import { Diversity2Rounded } from '@mui/icons-material';


const Nav = props => {
  const [open, setOpen] = React.useState(false);


  const handleOpen = () => {
    setOpen(!open);
  };


  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [lngDropdownOpen, setLngDropdownOpen] = React.useState(false);

  const handleLngDropdownOpen = () => {
    setLngDropdownOpen(!lngDropdownOpen);
  };



  const handleDropdownOpen = (event) => {
    event.preventDefault();
    setDropdownOpen(!dropdownOpen);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(!dropdownOpen);
  };




  const [language, setLanguage] = React.useState((i18n.language === "en" ? "GB" : i18n.language));


  const handleLanguage = (country) => {
    setLanguage(country);

    i18n.changeLanguage(country === "GB" ? "en" : country);
  };
  return (
    <div>


      <header className="top-header " >
        <NavLink to="/"><img src={logo3} className="logo" alt='Xper logo' /></NavLink>

        <div className="menu-button">

          <Hamburger toggled={open} toggle={handleOpen} aria-expanded={open ? "true" : "false"} size={40} distance="sm" hideOutline={false} label="Show menu" />
        </div>

      </header>
      <nav className={` ${open ? " show" : ""} navbar`}>
        <ul className='navbar-list'>
          <div  className='left'>
           <NavLink to="/"><img src={logo3} className="logo" alt='Xper logo' /></NavLink>
           <li>
            <div className='dropdown link' aria-expanded={dropdownOpen ? "true" : "false"} onClick={handleDropdownOpen} ><a href='...'>{i18next.t('nav.solutions')}</a>
              
              <ul className='dropdown-list'>
              <div className={`dropdown-content ${dropdownOpen ? "visable" : ""}`} >
                <li><NavLink to="/ccm" >{i18next.t('nav.ccm')}</NavLink></li>
                {/* <NavLink to="integrasjoner">{i18next.t('nav.integrations')}</NavLink>
                <NavLink to="automatisering">{i18next.t('nav.automation')}</NavLink> 
                <NavLink to="kommunikasjon">{i18next.t('nav.communication')}</NavLink> */}
                <li><NavLink to="universal-design">{i18next.t('nav.universal-design')}</NavLink></li>
              </div>
              </ul>
            </div>
          </li>

          <li><NavLink to="/kunder">{i18next.t('nav.customers')}</NavLink></li>
          <li><NavLink to="/partnere">{i18next.t('nav.partners')}</NavLink></li>
          {/*  <li><NavLink to="/blogg">{i18next.t('nav.blog')}</NavLink></li>*/}

          </div>
    
          <div className='right'>
        
            <li ><NavLink to="/skjema/kontakt-oss">{i18next.t('nav.contact')}</NavLink> </li>
            {/* <li ><NavLink to="/om-xper">{i18next.t('nav.about')}</NavLink></li> */}

            <li>
              <div className='dropdown lng-selector link' aria-expanded={lngDropdownOpen ? "true" : "false"} onClick={handleLngDropdownOpen} >
                <div className='lng-selector-button'>
                  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><g stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="round" ><path d="M8 14.667A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.334zM1.333 8h13.333"></path><path d="M8 1.333A10.2 10.2 0 0110.666 8 10.2 10.2 0 018 14.667 10.2 10.2 0 015.333 8 10.2 10.2 0 018 1.333v0z"></path></g></svg>
                  <div style={{ textTransform: 'uppercase' }}>{language === "GB" ? "en" : language}</div>
                  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 6l4 4 4-4" stroke="#1F3B21" strokeWidth="1.2" strokeLinecap="square"></path></svg>
                </div>
                <div className={`dropdown-content ${lngDropdownOpen ? "visable" : ""}`}>
                  <a onClick={() => handleLanguage("no")} >NO</a>
                  <a onClick={() => handleLanguage("en")}  >EN</a>
                </div>
              </div>
            </li>
          </div>
        </ul>

      </nav>
    </div >
  );
}

export default Nav;
