import React from 'react';
import '../App.css';
import i18next from '../i18n'


function UniversalDesign() {
  return (


    <div className='universal-design'>
      <header className="header">
        <h1>{i18next.t('pages.solutions.universal-design.title')}</h1>

        <p className='undertitle'>{i18next.t('pages.solutions.universal-design.undertitle')}</p>
      </header>
      <div className='content'>
        <div className='card'>
          <h2>{i18next.t('pages.solutions.universal-design.h2-1')}</h2>
          <p>{i18next.t('pages.solutions.universal-design.p-2')}</p>
          <p>{i18next.t('pages.solutions.universal-design.p-3')}</p>
          <p>{i18next.t('pages.solutions.universal-design.p-4')}</p>
          <p>{i18next.t('pages.solutions.universal-design.p-5')}</p>
          <p>{i18next.t('pages.solutions.universal-design.p-6')}</p>
          <p>{i18next.t('pages.solutions.universal-design.p-7')}</p>
          
          </div>
          <div className='card'>

          <h2>{i18next.t('pages.solutions.universal-design.h2-2')}</h2>
          <p>{i18next.t('pages.solutions.universal-design.p-8')}</p>
          
          </div>
          <div className='card'>
          <h2>{i18next.t('pages.solutions.universal-design.h2-3')}</h2>
          <p>{i18next.t('pages.solutions.universal-design.p-9')}</p>
          
          </div>
          <div className='card'>
          <h2>{i18next.t('pages.solutions.universal-design.h2-4')}</h2>
          <ul>
            <li><a href="https://www.uutilsynet.no/" target="_blank">{i18next.t('pages.solutions.universal-design.ul.a-1')}</a></li>
            <li><a href="https://www.uutilsynet.no/veiledning/dokumenter/741" target="_blank">{i18next.t('pages.solutions.universal-design.ul.a-2')}</a></li>
            <li><a href="https://digital-strategy.ec.europa.eu/en/policies/web-accessibility" target="_blank">{i18next.t('pages.solutions.universal-design.ul.a-3')}</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default UniversalDesign;
