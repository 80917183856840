import React from 'react';
import './App.css';

function Footer() {

  return (
    <footer>
      <div>© Copyright Xper 2023</div>
    </footer>
  );
}

export default Footer;
