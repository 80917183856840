import { useState } from "react";

import "../../App.css";
import i18next from "../../i18n";

import * as Yup from 'yup'
import "yup-phone";
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import axios from 'axios';



function Contact() {
    
  const [isSubmitted, setisSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const yupValidation = Yup.object().shape({
    first_name: Yup.string().required('Please enter some value.'),
    last_name: Yup.string().required('Please enter some value.'),
    email: Yup.string().required('Email is mandatory').email(),
  })
  const formOptions = { resolver: yupResolver(yupValidation) }
  const { register, handleSubmit, formState: {errors} } = useForm(formOptions)

  function onSubmit(data) {
     const dataString = 
     "Contact form - Xper Website \n" +
     "\nName: " + data.first_name + " "  +  data.last_name +
     "\nEmail: " + data.email + 
     "\nPhone number: "  +  data.phone +
     "\nCompany: "  +  data.company_name + 
     "\nCompany website : "  +  data.company_website + 
     "\nMessage : "  +  data.message ;

     
     setIsLoading(true);
     
      fetch("/api/contact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        subject: " Contact form - Xper website",
        body: dataString
    })
    })
    .then(response => {
            setisSubmitted(true);
            setIsLoading(false);
    })
    .catch(error => console.log(error));      
    
  }
    

  
  
  return ( 
    

<div className="contact">
          <header className="header">
            <h1>{i18next.t('pages.contact.title')}</h1>
          <div className='undertitle'></div>
          </header>

      {!isSubmitted
      ?
        !isLoading
        ?
        <div className="content">
        <div className="card">


        <h2>{i18next.t('pages.contact.fill-in-form')}</h2>
        <p>*{i18next.t('pages.contact.mandatory-text')}</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="contact-form">
              <div className="contact-form-devider">
                <label className="contact-form-label">
                  {i18next.t("pages.contact.input-fields.first-name")}*
                  <input
                    type="text"
                    aria-label={i18next.t("pages.contact.input-fields.first-name")}
                    aria-required="true"
                    name="first_name"
                    placeholder={i18next.t("pages.contact.fill-in")+i18next.t("pages.contact.input-fields.first-name")}
                    className={`contact-form-input ${errors.first_name ? 'is-invalid' : ''}`}
                    {...register('first_name')}
                  />
                  <div className="invalid-feedback">{errors.first_name?.message}</div>
                </label>
              </div>
              <div className="contact-form-devider">
                <label className="contact-form-label">
                {i18next.t("pages.contact.input-fields.last-name")}*
                  <input
                    type="text"
                    aria-label={i18next.t("pages.contact.input-fields.last-name")}
                    aria-required="true"
                    name="last_name"
                    placeholder={i18next.t("pages.contact.fill-in")+i18next.t("pages.contact.input-fields.last-name")}
                    className={`contact-form-input ${errors.last_name ? 'is-invalid' : ''}`}
                    {...register('last_name')}
                  />
                  <div className="invalid-feedback">{errors.last_name?.message}</div>
                </label>
              </div>
              <div className="contact-form-devider">
                <label className="contact-form-label">
                {i18next.t("pages.contact.input-fields.email")}*
                  <input
                    type="email"
                    aria-label={i18next.t("pages.contact.input-fields.email")}
                    aria-required="true"
                    name="email"
                    className={`contact-form-input ${errors.email ? 'is-invalid' : ''}`}
                    placeholder={i18next.t("pages.contact.fill-in")+i18next.t("pages.contact.input-fields.email")}
                    {...register('email')}
                  />
                  <div className="invalid-feedback">{errors.email?.message}</div>
                </label>
              </div>
              <div className="contact-form-devider">
                <label className="contact-form-label">
                {i18next.t("pages.contact.input-fields.phone")}
                  <input
                    type="tel"
                    aria-label={i18next.t("pages.contact.input-fields.phone")}
                    aria-required="false"
                    name="phone"
                    className={`contact-form-input`}
                    placeholder={i18next.t("pages.contact.fill-in")+i18next.t("pages.contact.input-fields.phone")}
                    {...register('phone')}
                  />
                  <div className="invalid-feedback">{errors.phone?.message}</div>
                </label>
              </div>
              <div className="contact-form-devider">
                <label className="contact-form-label">
                {i18next.t("pages.contact.input-fields.company-name")}
                  <input
                    type="text"
                    aria-label={i18next.t("pages.contact.input-fields.company-name")}
                    aria-required="false"
                    name="company_name"
                    className={"contact-form-input "}
                    placeholder={i18next.t("pages.contact.fill-in")+i18next.t("pages.contact.input-fields.company-name")}
                    {...register('company_name')}
                  />
                </label>
              </div>
              <div className="contact-form-devider">
                <label className="contact-form-label">
                {i18next.t("pages.contact.input-fields.company-website")}
                  <input
                    type="text"
                    aria-label={i18next.t("pages.contact.input-fields.company-website")}
                    aria-required="false"
                    name="company_website"
                    className={"contact-form-input "}
                    placeholder={i18next.t("pages.contact.fill-in")+i18next.t("pages.contact.input-fields.company-website")}
                    {...register('company_website')}
                  />
                </label>
              </div>
              <div className="contact-form-devider textarea">
                <label className="contact-form-label">
                {i18next.t("pages.contact.input-fields.message")}
                  <textarea
                    aria-label={i18next.t("pages.contact.input-fields.message")}
                    name="message"
                    className={"contact-form-input textarea "}
                    placeholder={i18next.t("pages.contact.fill-in")+i18next.t("pages.contact.input-fields.message")}
                    {...register('message')}
                  />
                      
                </label>
              </div>
              <div className="bottom">
               <button type="submit" className="button">{i18next.t("pages.contact.send-button")}</button>
              </div>
             </div>
          </form>
        </div>
      </div>
      
      : 
      <div className="center">   
       <div className="loader"></div>
      </div>
      
        
      :

      <div className='card'> 
        <h2>{i18next.t("pages.contact.thanks")}</h2>
        <p>{i18next.t("pages.contact.thanks-under")}</p>
      </div>
      }
      
      
      </div>

  );
}

export default Contact;
