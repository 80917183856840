import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "@fontsource/montserrat";
import "@fontsource/inter";
import { BrowserRouter } from 'react-router-dom';
import './i18n';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
);
reportWebVitals();
