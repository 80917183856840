import React from 'react';
import '../App.css';
import i18next from '../i18n'

import FlowChart from './components/FlowChart';

const ccm_image = require("../images/CCM.png")
const devider_image = require("../images/image 13.png")



function CCM() {
  return (

    <div className='ccm'>
      <header className="header">
        <h1>{i18next.t('pages.solutions.ccm.title')}</h1>
        
      <p className='undertitle'>{i18next.t('pages.solutions.ccm.undertitle')}</p>
      </header>

      <div className='content'>

        <FlowChart />


        <div className='card'>
          <h2>{i18next.t('pages.solutions.ccm.h1-2')}</h2>
          <p><b>{i18next.t('pages.solutions.ccm.b-1')}</b> {i18next.t('pages.solutions.ccm.p-2')}</p>
         
          <p>{i18next.t('pages.solutions.ccm.p-3')}</p>
          <p>{i18next.t('pages.solutions.ccm.p-4')}</p>
          <p>{i18next.t('pages.solutions.ccm.p-5')}</p>
          <p>{i18next.t('pages.solutions.ccm.p-6')}</p>
        </div>
      </div>
    </div>
  );
}

export default CCM;
