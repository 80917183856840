import { useState, useEffect } from "react";

import { useForm } from 'react-hook-form'

import '../App.css';
import i18next from '../i18n'

import axios from 'axios';

 function Login() {
  

  const { handleSubmit } = useForm()

  const [username,setUsername] = useState('');
  const [password,setPassword] = useState('');

  async function login () {
    const data = {username, password};
    console.log(data);
    axios.post('/api/login', data)
    .then(response => {
      console.log(response)
      // setData(response.data);
      // navigate(`/admin/edit/${response.data.insertedId}`, { replace: true });
    })
  }
 
  return (
    <div className="login">
      <header className="header">
          <h1>Login</h1>
        </header>
    
      <div className='card form'> 
      <form>
        <input type="text"
                placeholder={'Username'}
                value={username}
                onChange={ev => setUsername(ev.target.value)} />
        <input type="password"
                placeholder={'Password'}
                value={password}
                onChange={ev => setPassword(ev.target.value)} />
      
        <button style={{marginTop:'5px'}} className="submit-button" type="submit" onClick={handleSubmit(login)}>Login</button>
        
        </form>
    </div>
  </div>
   );
}

export default Login;



