import { useState } from "react";

import "../../App.css";
import i18next from "../../i18n";

import * as Yup from 'yup'
import "yup-phone";
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'


const seminar_img = require("../../images/Flowize/Seminarlogo.png")


function SeminarForm() {
    
  const [isSubmitted, setisSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const yupValidation = Yup.object().shape({
    first_name: Yup.string().required('Please enter some value.'),
    last_name: Yup.string().required('Please enter some value.'),
    email: Yup.string().required('Email is mandatory').email(),
  })
  const formOptions = { resolver: yupResolver(yupValidation) }
  const { register, handleSubmit, formState: {errors} } = useForm(formOptions)

  function onSubmit(data) {

     const dataString = 
     "Seminar påmeliding \n" +
     "\nName: " + data.first_name + " "  +  data.last_name +
     "\nEmail: " + data.email + 
     "\nPhone number: "  +  data.phone +
     "\nCompany: "  +  data.company_name + 
     "\nTitle : "  +  data.company_title 

     
     console.log(dataString);
     setIsLoading(true);
     
      fetch("/api/contact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        subject: " SeminarForm form - Xper website",
        body: dataString
    })
    })
    .then(response => {
            console.log(response)
            setisSubmitted(true);
            setIsLoading(false);
    })
    .catch(error => console.log(error));      
    
  }
    

  
  
  return ( 
    

<div className="contact">
          <header className="header">
            <h1>{i18next.t('pages.seminarForm.title')}</h1>
          <div className='undertitle'></div>
          </header>

      {!isSubmitted
      ?
        !isLoading
        ?
        <div className="content">

      <div className='card seminar'>
            <h2>{i18next.t("pages.seminarForm.info.title")}</h2>
            


            <p>{i18next.t("pages.seminarForm.info.body.p-1")}</p>
            <p>{i18next.t("pages.seminarForm.info.body.p-2")}</p>
            <p>{i18next.t("pages.seminarForm.info.body.p-3")}</p>
            
            <p>
              
            <strong>{i18next.t("pages.seminarForm.info.body.p-5.tid")}</strong> {i18next.t("pages.seminarForm.info.body.p-5.tidValue")} <br/>
            <strong>{i18next.t("pages.seminarForm.info.body.p-5.hvor")}</strong> {i18next.t("pages.seminarForm.info.body.p-5.hvorValue")} <br/>
            <strong>{i18next.t("pages.seminarForm.info.body.p-5.pris")}</strong> {i18next.t("pages.seminarForm.info.body.p-5.prisValue")} <br/>
            </p>
            <br/>
            <h3>{i18next.t("pages.seminarForm.info.program.title")}</h3>
            <p><strong>{i18next.t("pages.seminarForm.info.program.time1")}</strong> <br/> {i18next.t("pages.seminarForm.info.program.activity1")}</p>
            <p><strong>{i18next.t("pages.seminarForm.info.program.time2")}</strong> <br/> {i18next.t("pages.seminarForm.info.program.activity2")}</p>
            <p><strong>{i18next.t("pages.seminarForm.info.program.time3")}</strong> <br/> {i18next.t("pages.seminarForm.info.program.activity3")}</p>
            <p><strong>{i18next.t("pages.seminarForm.info.program.time4")}</strong> <br/> {i18next.t("pages.seminarForm.info.program.activity4")}</p>
            <p><strong>{i18next.t("pages.seminarForm.info.program.time5")}</strong> <br/> {i18next.t("pages.seminarForm.info.program.activity5")}</p>
            <p><strong>{i18next.t("pages.seminarForm.info.program.time6")}</strong> <br/> {i18next.t("pages.seminarForm.info.program.activity6")}</p>
            <p><strong>{i18next.t("pages.seminarForm.info.program.time7")}</strong> <br/> {i18next.t("pages.seminarForm.info.program.activity7")}</p>
            <p><strong>{i18next.t("pages.seminarForm.info.program.time8")}</strong> <br/> {i18next.t("pages.seminarForm.info.program.activity8")}</p>
            <p><strong>{i18next.t("pages.seminarForm.info.program.time9")}</strong> <br/> {i18next.t("pages.seminarForm.info.program.activity9")}</p>
            <br/>
            <p>
              {i18next.t("pages.seminarForm.info.body.p-4")}
              <br/>
              {i18next.t("pages.seminarForm.info.program.questions")} CEO <a href="mailto:b.sorum@xperconsulting.no">Bjarne</a>
            </p>
            <img alt={"Seminar logo"} className="seminar-logo" src={seminar_img} />

          </div>

       



        <div className="card seminar ">
          <div className=" booked">Fully booked</div> 
        <div className=" disabled" > </div>

        <h2>{i18next.t('pages.seminarForm.fill-in-form')}</h2>
        <p>{i18next.t('pages.seminarForm.mandatory-text')}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="contact-form">
              <div className="contact-form-devider">
                <label className="contact-form-label">
                  {i18next.t("pages.seminarForm.input-fields.first-name")}
                  <input
                    disabled
                    type="text"
                    aria-label={i18next.t("pages.seminarForm.input-fields.first-name")}
                    aria-required="true"
                    autocomplete="given-name"
                    name="first_name"
                    placeholder={i18next.t("pages.seminarForm.fill-in")+i18next.t("pages.seminarForm.input-fields.first-name")}
                    className={`contact-form-input ${errors.first_name ? 'is-invalid' : ''}`}
                    {...register('first_name')}
                  />
                  <div className="invalid-feedback">{errors.first_name?.message}</div>
                </label>
              </div>
              <div className="contact-form-devider">
                <label className="contact-form-label">
                {i18next.t("pages.seminarForm.input-fields.last-name")}
                  <input
                    disabled
                    type="text"
                    aria-label={i18next.t("pages.seminarForm.input-fields.last-name")}
                    aria-required="true"
                    autocomplete="family-name"
                    name="last_name"
                    placeholder={i18next.t("pages.seminarForm.fill-in")+i18next.t("pages.seminarForm.input-fields.last-name")}
                    className={`contact-form-input ${errors.last_name ? 'is-invalid' : ''}`}
                    {...register('last_name')}
                  />
                  <div className="invalid-feedback">{errors.last_name?.message}</div>
                </label>
              </div>
              <div className="contact-form-devider">
                <label className="contact-form-label">
                {i18next.t("pages.seminarForm.input-fields.email")}
                  <input
                    disabled
                    type="email"
                    aria-label={i18next.t("pages.seminarForm.input-fields.email")}
                    aria-required="true"
                    autoComplete="email"
                    name="email"
                    className={`contact-form-input ${errors.email ? 'is-invalid' : ''}`}
                    placeholder={i18next.t("pages.seminarForm.fill-in")+i18next.t("pages.seminarForm.input-fields.email")}
                    {...register('email')}
                  />
                  <div className="invalid-feedback">{errors.email?.message}</div>
                </label>
              </div>
              <div className="contact-form-devider">
                <label className="contact-form-label">
                {i18next.t("pages.seminarForm.input-fields.phone")} 
                  {i18next.t("pages.seminarForm.optional")}
                  <input
                    disabled
                    type="tel"
                    aria-label={i18next.t("pages.seminarForm.input-fields.phone")}
                    aria-required="false"
                    autoComplete="tel"
                    name="phone"
                    className={`contact-form-input`}
                    placeholder={i18next.t("pages.seminarForm.fill-in")+i18next.t("pages.seminarForm.input-fields.phone")}
                    {...register('phone')}
                  />
                  <div className="invalid-feedback">{errors.phone?.message}</div>
                </label>
              </div>
              <div className="contact-form-devider">
                <label className="contact-form-label">
                {i18next.t("pages.seminarForm.input-fields.company-name")} 
                {i18next.t("pages.seminarForm.optional")}
                  <input
                    disabled
                    type="text"
                    aria-label={i18next.t("pages.seminarForm.input-fields.company-name")}
                    aria-required="false"
                    autoComplete="organization"
                    name="company_name"
                    className={"contact-form-input "}
                    placeholder={i18next.t("pages.seminarForm.fill-in")+i18next.t("pages.seminarForm.input-fields.company-name")}
                    {...register('company_name')}
                  />
                </label>
              </div>
              <div className="contact-form-devider">
                <label className="contact-form-label">
                {i18next.t("pages.seminarForm.input-fields.company-title")} 
                {i18next.t("pages.seminarForm.optional")}
                  <input
                    disabled
                    type="text"
                    aria-label={i18next.t("pages.seminarForm.input-fields.company-title")}
                    aria-required="false"
                    autoComplete="organization-title"
                    name="company_title"
                    className={"contact-form-input "}
                    placeholder={i18next.t("pages.seminarForm.fill-in")+i18next.t("pages.seminarForm.input-fields.company-title")}
                    {...register('company_title')}
                  />
                </label>
              </div>
              
              <div className="bottom">
               <button type="submit" disabled className="button">{i18next.t("pages.seminarForm.send-button")}</button>
              </div>
             </div>
          </form>
        </div>
      </div>
      
      : 
      <div className="center">   
       <div className="loader"></div>
      </div>
      
        
      :

      <div className='card'> 
        <h2>{i18next.t("pages.seminarForm.thanks")}</h2>
        <p>{i18next.t("pages.seminarForm.thanks-under")}</p>
      </div>
      }
      
      
      </div>

  );
}

export default SeminarForm;
