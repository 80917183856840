export const teamMemberData = [
    {
        "name": "Bjarne Sørum",
        "role": "Daglig leder",
        "image": require("../../images/team/Bjarne-2048x2048.jpg"),
        "competence": [{ "title": "Management og utvikling", "things": ["Administrasjon", "Finans", "IT Strategi", "Prosjekt styring", "Applikasjonsutvikling"] }, { "title": "IT løsninger", "things": ["Betalingsløsninger", "Prosessautomatisering", "Integrasjons- og løsningsarkitekt"] }, { "title": "IT operativsystemer og infrastruktur", "things": ["Mainframe", "Unix", "Linux", "Windows"] }, { "title": "Tjenesteorientert arkitektur", "things": ["Meldingskøer og overføring av data"] }, { "title": "Informasjons- og dokumentflyt", "things": ["Enterprise Content Management"] }, { "title": "IT løsninger", "things": ["Flowize", "OpenText Exstream", "Andre CCM-løsninger", "Kursinstruktør og teknisk mentor"] }]
    },
    {
        "name": "Tomas Hajek",
        "role": "Integrasjons- og løsningsekspert",
        "image": require("../../images/team/TomasHajek.jpg"),
        "competence": [{ "title": "IT operativsystemer og infrastruktur", "things": ["Mainframe", "Unix", "Linux", "Windows"] }, { "title": "Tjenesteorientert arkitektur", "things": ["API", "REST/SOAP", "Web Services", "Meldingskøer", "Databaser", "Java"] }, { "title": "Informasjons- og dokumentflyt", "things": ["Multikanal løsninger", "Data & Content Analytics", "Utvikling basert på IBM Watson Explorer"] }, { "title": "IT løsninger", "things": ["Flowize", "OpenText Exstream", "Quadient Inspire", "Andre CCM-løsninger", "Teknisk mentor"] }]
    },
    {
        "name": "Stefan Vilceloiu",
        "role": "Integrasjons- og løsningsarkitekt",
        "image": require("../../images/team/StefanVilceloiu.png"),
        "competence": [{ "title": "Webutvikling", "things": ["HTML", "CSS", "XML", "Json"] }, { "title": "IT operativsystem og infrastruktur", "things": ["Windows", "Linux"] }, { "title": "Tjenesteorientert arkitektur", "things": ["Webservices & API – Java", "Webservices & API – PHP", "Data & Content Analytics", "Java", "Javascript", "PHP", "CMS – Enonic CMS", "PurpleJS"] }, { "title": "BI / Datavarehus", "things": ["MySQL", "PostgreSQL", "Oracle"] }, { "title": "IT løsninger", "things": ["Flowize", "OpenText Extream", "Andre CCM-løsninger"] }]

    },
    {
        "name": "Yaowapa Pornsamutsin",
        "role": "Løsningsekspert og utvikler",
        "image": require("../../images/team/YaowapaPornsamutsin.jpg"),
        "competence": [{ "title": "Web-sider og web-applikasjoner", "things": ["Wordpress", "Joomla", "HTML", "CSS", "XML", "JavaScript"] }, { "title": "BI / Datavarehus", "things": ["WhereScape RED", "SQL Server Management Studio (SSMS),", "Oracle", "Master Data Services (MDS)", "Qlik Sense"] }, { "title": "IT løsninger", "things": ["Flowize", "OpenText Exstream", "Objectif Lune PlanetPress", "Customer Communications Management (CCM)", "Andre CCM-løsninger"] }]

    },
    {
        "name": "Rune Hansen",
        "role": "Salgs- og markedsansvarlig",
        "image": require("../../images/team/RuneHansen.jpg"),
        "competence": [{ "title": "", "things": ["Forsikring- og finansprodukter"] }, { "title": "", "things": ["Strøm- og energibransjen"] }, { "title": "Informasjons- og dokumentflyt", "things": ["GDPR", "BPMN og DMN", "Sparx Enterprise Architect", "Løsninger for arkivering av dokumenter"] }, { "title": "IT løsninger", "things": ["Flowize", "OpenText Exstream", "CCM løsninger"] }]

    },
    {
        "name": "Pål Vinland",
        "role": "Løsningsekspert og utvikler",
        "image": require("../../images/team/PålVinland.jpg"),
        "competence": [{ "title": "", "things": ["Forsikring- og finansprodukter"] }, { "title": "", "things": ["Informasjons- og dokumentflyt"] }, { "title": "Applikasjonsutvikling og løsningsarkitektur", "things": ["HTML", "CSS", "JavaScript"] }, { "title": "IT løsninger", "things": ["Flowize", "OpenText Exstream", "Andre CCM-løsninger", "Medlem av OpenText Exstream Product Advisory Council"] }]
    },
    {
        "name": "Pavel Pajer",
        "role": "Tittel",
        "image": require("../../images/team/empty.png"),
        "competence": []
    },
    {
        "name": "Vidar Berge",
        "role": "Tittel",
        "image": require("../../images/team/empty.png"),
        "competence": []
    },
    {
        "name": "Jens Ugland",
        "role": "Fullstack utvikler",
        "image": require("../../images/team/jensugland.png"),
        "competence": [{ "title": "Webutvikling", "things": ["WordPress", "HTML", "CSS", "JavaScript", "Php"] },
        { "title": "Programmering", "things": ["Phyton", "Java", "API", "SQI", "Semantic web", "TDD"] },
        { "title": "Grafisk design", "things": ["Adobe Photoshop", "Adobe Premier Pro", "Adobe Illustator", "Adobe XD"] },
        { "title": "IT løsninger", "things": ["Flowize"] }]
    },
    {
        "name": "Suparna Chowdhury ",
        "role": "Løsningsekspert og utvikler",
        "image": require("../../images/team/empty.png"),
        "competence": []
    },

];