import React from 'react';
import '../App.css';

import i18next from '../i18n'


const crawtech_logo = require("../images/partners/crawtech-logo.png")
const IBM_logo = require("../images/partners/IBM.png")
const OpenText_logo = require("../images/partners/Opentext.png")
const IBM_illustration = require("../images/partners/IBM_Illustration.png")
const CrawfordTect_Illustration = require("../images/partners/CrawfordTect_Illustration.png")

function Partners() {
  return (

    <div className='partners'>
      <header className="header">
        <h1>{i18next.t('pages.partners.technology-partners.h1')}</h1>
    

      <div className='undertitle ' >
          <p>{i18next.t('pages.partners.technology-partners.p-1')}</p>
          <p>{i18next.t('pages.partners.technology-partners.p-2')}</p>
          
          <p>{i18next.t('pages.partners.technology-partners.p-3')}</p>
          <ul>
            <li>{i18next.t('pages.partners.technology-partners.ul-li-1')}</li>
            <li>{i18next.t('pages.partners.technology-partners.ul-li-2')}</li>
            <li>{i18next.t('pages.partners.technology-partners.ul-li-3')}</li>
          </ul>
         
      </div>
      </header>
      <div className='content '>
       
      <div className='card'>
        
        <h2>{i18next.t('pages.partners.openText-exstream.h2')}</h2>
        <p>{i18next.t('pages.partners.openText-exstream.p-1')}</p>
        <p>{i18next.t('pages.partners.openText-exstream.p-2')}</p>
        
        <img className='partner_logo' alt="logo OpenText Partner Reseller Silver" src={OpenText_logo} />

      </div>
        
      <div className='card'>
        <h2>{i18next.t('pages.partners.ibm-cloud-paks.h2')}</h2>
        <p>{i18next.t('pages.partners.ibm-cloud-paks.p-1')}</p>
        <p>{i18next.t('pages.partners.ibm-cloud-paks.p-2')}</p>
        <img className='partner_logo' alt="logo IBM" src={IBM_logo} />
        <img className='partner_ilustration' alt="IBM illustration" src={IBM_illustration} />
      </div>
      
      <div className='card'>
        <h2>{i18next.t('pages.partners.accessibilityNow.h2')}</h2>
        <p><b>{i18next.t('pages.partners.accessibilityNow.b-1')}</b> {i18next.t('pages.partners.accessibilityNow.p-1')}</p>
        <p>{i18next.t('pages.partners.accessibilityNow.p-2')}</p>
        <img className='partner_logo'  alt="Crawford Technologies logo" src={crawtech_logo} />
        <img className='partner_logo' alt="Crawford Technologies illustration" src={CrawfordTect_Illustration} />
      </div>
      </div>
    </div>
  );
}

export default Partners;
