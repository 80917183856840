import { useState, useEffect } from "react";

import './Flowchart.css';
import i18next from '../../i18n'

import { ArcherContainer, ArcherElement } from 'react-archer';


import { useMediaQuery } from 'react-responsive'

import { MailOutlined, ChatOutlined, PhonelinkRingOutlined, AlternateEmailOutlined, InsertDriveFileOutlined, StorageOutlined } from '@mui/icons-material';



function FlowChart() {

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)'})


  return (
    <div className="app">
      <ArcherContainer
        offset={20}
        strokeColor="black"
      >
       <div className="container">
        

        <div className="col colDevider">
          <ArcherElement
            id="element1"
            relations={[
              {
                targetId: 'element4',
                targetAnchor:  !isDesktopOrLaptop ? 'top' :'left',
                sourceAnchor:  !isDesktopOrLaptop ? 'bottom' :'right',
              },
            ]}
          >
            <div className="element">{i18next.t('pages.solutions.ccm.flow-chart.buisness-app')}</div>
          </ArcherElement>
        
          <ArcherElement
            id="element2"
            relations={[
              {
                targetId: 'element4',
                targetAnchor:  !isDesktopOrLaptop ? 'top' :'left',
                sourceAnchor:  !isDesktopOrLaptop ? 'bottom' :'right',
              },
            ]}
          >
            <div className="element leftElement">{i18next.t('pages.solutions.ccm.flow-chart.buisness-app')}</div>
          </ArcherElement>
        
          <ArcherElement
            id="element3"
            relations={[
              {
                targetId: 'element4',
                targetAnchor:  !isDesktopOrLaptop ? 'top' :'left',
                sourceAnchor:  !isDesktopOrLaptop ? 'bottom' :'right',
              },
            ]}
          >
            <div className="element">{i18next.t('pages.solutions.ccm.flow-chart.buisness-app')}</div>
          </ArcherElement>
        
        </div>
        <span aria-label="Archers to"></span>
        <div className="col colDevider">
          <ArcherElement id="element4"
            relations={[
            {
              targetId: 'element5',
              targetAnchor:  !isDesktopOrLaptop ? 'top' :'left',
              sourceAnchor:  !isDesktopOrLaptop ? 'bottom' :'right',
            },
            {
              targetId: 'element6',
              targetAnchor:  !isDesktopOrLaptop ? 'top' :'left',
              sourceAnchor:  !isDesktopOrLaptop ? 'bottom' :'right',
            },
            {
              targetId: 'element7',
              targetAnchor:  !isDesktopOrLaptop ? 'top' :'left',
              sourceAnchor:  !isDesktopOrLaptop ? 'bottom' :'right',
            },
          ]}
          >
            <div className="row">

            <div className="element elementMiddle">{i18next.t('pages.solutions.ccm.flow-chart.document')}</div>
            <div className="element elementMiddle">{i18next.t('pages.solutions.ccm.flow-chart.preparing')}</div>
            <div className="element elementMiddle">{i18next.t('pages.solutions.ccm.flow-chart.multichannel')}</div>
            </div>
            
          </ArcherElement>
            
        </div>
        
        <span aria-label="Archers to"></span>
        <div className="col colDevider">
          <ArcherElement id="element5">
            <div className="col">
             <div className="icon " aria-label="Mail icon"><MailOutlined/></div>
             <div className="icon rightElementHalf" aria-label="Chat icon"><ChatOutlined/></div>
            </div>
          </ArcherElement>
        
          <ArcherElement id="element6">

            <div className="col rightElement">
              <div className="icon " aria-label="Phone icon"><PhonelinkRingOutlined /></div>
              <div className="icon " aria-label="@ icon"><AlternateEmailOutlined /></div>
            </div>
          </ArcherElement>
        
          <ArcherElement id="element7">
            
          <div className="col ">
            <div className="icon rightElementHalf" aria-label="File icon"><InsertDriveFileOutlined /></div>
            <div className="icon " aria-label="Database icon"><StorageOutlined /></div>
          </div>
            </ArcherElement>
        
        </div>
        
       </div>
      </ArcherContainer>
    </div>
  );
}

export default FlowChart;



